<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import store from "@/store";
import rulesModule from "@/store/settings/fulfillment/rules";
import {onUnmounted} from "@vue/composition-api";

export default {
  name: 'Rules',
  created() {
    this.$emit('updateMenu', 'settings-fulfillment-rules')
  },
  setup() {
    const MODULE_NAME = 'rules'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, rulesModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
};
</script>
<style>

</style>