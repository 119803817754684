export default {
  GET(state, data) {
    state.rule = { ...data }
  },
  LIST(state, data) {
    state.rules = data
  },
  SET_FORM(state, data) {
    state.rulesForm = { ...data }
  },
}
